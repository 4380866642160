.forgotPassword {
  .inner {
    color: $blue;
    margin-top: 50px;

    padding-right: 10%;
    padding-left: 5%;

    .ligne1 {
      font-size: 35px;
      margin-bottom: 30px;

      span {
        margin-left: 40px;
      }
    }

    h5 {
      margin-left: 6%;
    }

    .data {
      text-align: center;
      margin-top: 40px;

      .field {
        margin-bottom: 20px;
      }
    }

    button {
      border: 0px;
      font-weight: bold;
    }
  }
}
