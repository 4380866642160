.ajoutpreparation {
  .inner {
    @media only screen and (min-width: 600px) {
      margin-left: 150px;
      margin-right: 100px;

      margin-bottom: 30px;

      .field {
        margin-bottom: 10px;

        .input {
          width: 80%;
        }
      }

      .textArea {
        margin-top: 20px;
        .input {
          width: 80%;
          font-family: $poppins;
        }
      }

      label {
        margin-right: 20px;
      }

      .trash {
        cursor: pointer;
      }

      .infos {
        display: flex;
        justify-content: space-between;

        div {
          .btn {
            display: flex;
            justify-content: space-evenly;

            button {
              border: 0px;
              color: $blue;
            }
          }
        }
      }
    }

    @media only screen and (max-width: 600px) {
      margin-left: 50px;
      margin-right: 20px;
      margin-bottom: 30px;

      h1{
        padding-top: 15px;
        padding-left: 20px;
      }

      .field {
        margin-bottom: 10px;
      }

      .textArea {
        margin-top: 20px;
        .input {
          width: 100%;
          font-family: $poppins;
        }
      }

      label {
        margin-right: 20px;
      }

      .trash {
        cursor: pointer;
      }

      .infos {

        div {
          width: 100%;

          .btn {
            display: flex;
            justify-content: space-evenly;

            button {
              border: 0px;
              color: $blue;
            }
          }
        }
      }
    }
  }
}
