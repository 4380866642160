@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");

$poppins: "Poppins", sans-serif;

$blue: rgb(13, 19, 46);
$beige: rgb(248, 246, 246);
$beigeDarker: rgba(199, 202, 209, 0.705);
$red: rgb(252, 80, 80);
$electric: rgba(31, 72, 185, 0.76);
$transparentBlue: rgba(0, 68, 255, 0.034);
$clearBlack: rgba(0, 0, 0, 0.205);

body {
  background-color: $beige;
  font-family: $poppins;
  font-weight: 100;
  padding: 0;
  margin: 0;
}

button {
  color: $electric;
  text-decoration: none;
  cursor: pointer;
  background-color: $beige;
  border: 1px solid;
  border-radius: 8px;
  padding: 6px;
  padding-left: 10px;
  padding-right: 10px;
  transition: 0.25s;
  margin-top: 20px;
  font-family: $poppins;
}

button:hover {
  transform: scale(1.1);
  color: $red;
}

a {
  color: $electric;
  text-decoration: none;
  transition: 0.5s;
}

a:hover {
  color: $red;
}

input {
  border: 0px;
  border-bottom: 1px solid black;
  background-color: transparent;
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 100;
}

h1 {
  @media only screen and (min-width: 600px) {
    font-size: 35px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 20px;
  }
}

textarea {
  background-color: transparent;
  border-radius: 6px;
  outline: none;
}

.actions {
  display: flex;
  justify-content: center;

  button {
    margin-right: 40px;
    margin-left: 40px;
    font-size: 16px;
  }
}

.progess{
  width:fit-content;
  display:block;
  margin:auto;
  text-align:center;
  margin-top: 50px;
}