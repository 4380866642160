.navbar {
  padding-left: 20px;
  position: fixed;

  a .back {
    margin-bottom: 40px;
    margin-top: 20px;
    margin-left: 6px;
    color: $blue;
  }

  .navIcons {
    .icon {
      font-size: 30px;
      margin-bottom: 20px;
    }

    a {
      color: $blue;
      transition: 0.25s;
      padding-bottom: 10px;
    }

    a:hover {
      margin-left: 15px;
    }

    .nav-active {
      margin-left: 15px;
    }
  }

  @media only screen and (max-width: 600px) {
    a .back {
      font-size:15px;
    }

    .navIcons{
      display:none;
    }
  }
}
