.connection {
  color: $blue;

  height: fit-content;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 50px;

  padding-right: 10%;
  padding-left: 10%;

  h3,
  h5 {
    font-weight: 300;
  }

  .textHaut {
    .ligne1 {
      margin-left: -10%;
      display: flex;
      justify-content: space-between;
      font-size: 35px;
      margin-bottom: 30px;

      span {
        margin-left: 40px;
      }

      button {
        height: 30px;
        margin-top: 20px;
      }

      button:hover {
        transform: scale(1);
      }
    }

    h5 {
      margin-top: -10px;
    }
  }

  .data {
    text-align: center;
    margin-top: 40px;

    .field {
      margin-bottom: 20px;
    }
  }

  .pasInscrit {
    margin-top: 50px;

    h3 {
      font-size: 18px;
    }

    a {
      margin-left: 20px;
    }
  }
}
