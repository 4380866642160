.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.footer,
.footer2 {
  .innerFooter {
    background-color: $electric;
    color: white;
    padding-top:3px;
    padding-bottom:3px;
    font-size: 15px;

    display: flex;
    justify-content: space-between;

    .left {
      width: 33%;
      display: flex;
      justify-content: space-around;

      .icon {
        width: 20px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .innerFooter {
      display:none;
    }
  }
}
