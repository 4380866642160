.inscription {
  height: 100vh;
  padding-right: 5%;

  .ligne1 {
    display: flex;
    justify-content: left;
    padding-top: 40px;

    a {
      margin-top: 15px;
      color: $blue;
      margin-left: 5%;
    }

    h1 {
      margin-left: 40px;
      margin-top: 5px;
      margin-bottom: 30px;
      font-size: 30px;
    }
  }

  .info {
    h3 {
      text-align: center;
      color: $electric;
    }

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    ul {
      list-style-type: none;
      margin-left: -40px;
      width: 100%;

      .company {
        width: fit-content;
        display: block;
        margin: auto;
        margin-bottom: 20px;

        button {
          margin-top: 0px;
          color: $blue;
          text-decoration: underline;
          background-color: transparent;
          font-size: 12px;
        }
      }

      li {
        margin-bottom: 5px;
        text-align: right;

        label {
          margin-right: 20px;
        }
      }

      a {
        width: fit-content;
        display: block;
        margin: auto;
        margin-top: 5vh;
      }
    }
  }

  button {
    width: fit-content;
    display: block;
    margin: auto;
    margin-top: 40px;
    border: 0px;
  }
}
