.ajoutproduit {
  height: 100vh;

  @media only screen and (min-width: 600px) {
    .inner {
      margin-left: 150px;
      padding-bottom: 5px;

      h3 {
        margin-top: 50px;
        font-weight: bold;
        color: $electric;
      }

      .identif {
        padding-right: 20%;
      }

      p {
        width: 100%;
        font-size: 13px;
      }

      ul {
        text-align: right;
        margin-right: 10%;
        margin-top: 40px;
        margin-left: -40px;
        list-style: none;

        .uploadIA{
          font-size: 13px;
          font-weight: bold;
          text-align:left;
          
          input{
            width:30%;
            border:0px;
          }
        }

        li {
          margin-top: 10px;

          input {
            margin-left: 20px;
            width: 80%;
          }

          .table {
            width: 80%;
            border: 1px solid $blue;
            border-radius: 5px;
            background-color: $transparentBlue;
            text-align:center;

            .row,
            .row1 {
              display: flex;
              justify-content: space-between;
              padding: 5px 20px 5px 20px;

              div{
                width:33%;
              }

              .edit {
                margin-left: 20px;
                color: $blue;
                cursor: pointer;
              }

              .cal {
                width: 50px;
              }
            }

            .row1 {
              color: $electric;
              font-weight: bold;
            }

            .row:hover {
              font-weight: bold;
            }

            .barre {
              width: 100%;
              border-bottom: 1px solid $blue;
            }
          }
        }

        .mainInfo {
          width: 40%;
          input {
            width: 50%;
          }
        }

        .tableIng,
        .tableControle {
          margin-top: 40px;
          display: flex;
          justify-content: center;

          .table {
            width: 70%;
          }

          label {
            margin-right: 27px;
          }
        }

        .description {
          width: 100%;
          text-align: left;
          input {
            width: 75%;
          }
        }

        .inputDialogIng {
          margin: 20px;
        }

        .allergenes {
          text-align: left;
          display: flex;
          justify-content: space-evenly;
        }

        .tableControle {
          margin-top: -20px;

          label {
            margin-right: 10px;
          }

          input {
            margin-left: 10px;
            margin-right: 10px;
            border: 0;
          }

          input::placeholder {
            color: $electric;
            font-family: $poppins;
          }
        }

        .calcul {
          margin-right: 10%;
        }

        .add {
          font-size: 25px;
          width: 80%;
          text-align: center;
          margin-left: 20%;
          cursor: pointer;
        }

        .radioBtn {
          margin-top: 40px;
          text-align: left;
          margin-left:10px;
        }
      }

      .imp,
      .impPoids {
        color: $red;
        font-weight: bold;
      }

      .valNut {
        display: flex;
        flex-wrap: wrap;
        margin-right: 20%;
        margin-top: 0px;

        li {
          width: 50%;

          input {
            width: 30%;
          }
        }
      }

      .valNut1{
        display:flex;
        justify-content:space-evenly;
        text-align:left;
        margin-top: 0px;

        li{
          input{
            width:100px;
          }
        }

        .sousChamp{
          list-style-type:circle;
          margin-left: 40px;
          color:rgb(49, 48, 48);
        }
      }

      .paletteVide {
        text-align: left;

        input {
          width: 8%;
        }
      }

      .infoLog {
        margin-top: 0px;
        text-align: left;

        input {
          width: 15%;
        }

        .conditions {
          input {
            width: 60%;
          }
        }
      }

      .impPoids {
        margin-top: 40px;
        margin-bottom: 0px;
      }

      .poids,
      .poidsMicrobio {
        display: flex;
        justify-content: center;
        margin-top: 0px;

        input {
          width: 20%;
        }
      }

      .poidsMicrobio {
        input {
          width: 60%;
        }

        li {
          margin-left: 50px;
        }
      }

      textarea {
        width: 90%;
        margin-top: -10px;
        height: 100px;
      }

      .actions {
        margin-bottom: 40px;
        margin-top: 20px;
        margin-left: -50px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .inner {
      margin-left: 45px;
      padding-right:30px;
      width: 100%;

      h1{
        padding-top: 14px;
      }

      h3 {
        margin-top: 30px;
        font-weight: bold;
        color: $electric;
      }

      p {
        font-size: 13px;
      }

      ul {
        margin-top: 40px;
        margin-left: -40px;
        list-style: none;

        .uploadIA{
          font-size: 13px;
          font-weight: bold;
          text-align:left;
          
          input{
            border:0px;
            margin-top: 10px;
            margin-left: 0px;
          }
        }

        li {
          margin-top: 10px;
          width: 100%;
          text-align:right;

          input {
            margin-left: 20px;
            width: 50%;
          }

          .table {
            width: 80%;
            border: 1px solid $blue;
            border-radius: 5px;
            background-color: $transparentBlue;
            text-align:center;
            margin-left:5px;

            .row,
            .row1 {
              display: flex;
              justify-content: space-between;
              padding: 5px 20px 5px 20px;

              .edit {
                margin-left: 20px;
                color: $blue;
                cursor: pointer;
              }

              .cal {
                width: 50px;
              }
            }

            .row1 {
              color: $electric;
              font-weight: bold;
            }

            .row:hover {
              font-weight: bold;
            }

            .barre {
              width: 100%;
              border-bottom: 1px solid $blue;
            }
          }
        }

        .mainInfo {
          width: 100%;
          text-align:right;
          input {
            width: 50%;
          }
        }

        .tableIng,
        .tableControle {
          margin-top: 40px;
          display: flex;
          justify-content: center;
        }

        .description {
          width: 100%;
          text-align: left;
          input {
            width: 100%;
            margin-right: 20px;
          }
        }

        .inputDialogIng {
          margin: 20px;
        }

        .allergenes {
          text-align: left;
          display: flex;
          justify-content: space-evenly;
        }

        .tableControle {
          margin-top: -20px;

          label {
            margin-right: 5px;
          }

          input {
            margin-left: 10px;
            margin-right: 10px;
            border: 0;
          }

          input::placeholder {
            color: $electric;
            font-family: $poppins;
          }
        }

        .calcul {
          margin-right: 10%;
        }

        .add {
          font-size: 25px;
          width: 80%;
          text-align: center;
          margin-left: 20%;
          cursor: pointer;
        }

        .radioBtn {
          margin-top: 40px;
          display: flex;
          justify-content: flex-end;

          label {
            margin-right: 30px;
          }

          .radio {
            width: 80%;
          }
        }
      }

      .imp,
      .impPoids {
        color: $red;
        font-weight: bold;
      }

      .valNut {
        display: flex;
        flex-wrap: wrap;
        margin-right: 20%;
        margin-top: 0px;

        li {
          width: 50%;

          input {
            width: 30%;
          }
        }
      }

      .valNut1{
        text-align:left;
        margin-top: 0px;

        .sousChamp{
          font-size:14px;
          color:rgb(49, 48, 48);

          input{
            width:30%;
          }
        }
      }

      .paletteVide {
        text-align: left;

        input {
          width: 8%;
        }
      }

      .infoLog {
        margin-top: 0px;
        text-align: left;
        font-size: 15px;

        input{
          margin-bottom: 20px;
        }
      }

      .impPoids {
        margin-top: 40px;
        margin-bottom: 0px;
      }

      .poids,
      .poidsMicrobio {
        input {
          width: 30%;
        }
      }

      textarea {
        width: 100%;
        margin-top: -10px;
        height: 100px;
      }

      .actions {
        margin-bottom: 40px;
        margin-top: 20px;
        margin-left: -50px;
      }
    }
  }
}
