.accueil {
  .accueil1 {
    background-color: rgb(24, 58, 197);
    color: $beige;

    height: 100vh;
    margin: auto;

    .text {
      font-size: 30px;
      padding: 50px;
      padding-top: 20px;
    }

    img {
      margin-left: 50px;
      width: 70%;
      margin-top: 180px;
    }

    a {
      margin-top: 20px;
      margin-left: 49%;
    }
  }

  .accueil2 {
    background-color: $beige;
    height: 100vh;

    img {
      height: 40px;
      margin: 10px;
    }

    .inner {
      text-align: center;
      margin-top: 4%;
      font-size: 40px;

      .vous {
        margin-bottom: 30px;
        color: rgba(0, 0, 0, 0.822);
        font-size: 30px;
      }

      button {
        letter-spacing: 2px;
        // border: 1px solid $clearBlack;
        border: 0px;
        font-size: 25px;
        margin-top: -20px;
        font-weight: 100;
        color: rgba(17, 17, 17, 0.884);
        margin: 20px;
        width: 270px;
        // background-color: $transparentBlue;

        div {
          font-size: 80px;
          margin-bottom: -20px;
        }
      }
    }
  }
}
