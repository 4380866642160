.compteperso {
  .inner {
    @media only screen and (min-width: 600px) {
      margin-left: 150px;
    }
    @media only screen and (max-width: 600px) {
      margin-left: 40px;
    }

    margin-right: 100px;

    h1{
      padding-top: 15px;
      padding-left: 20px;
    }

    .data {
      @media only screen and (min-width: 600px) {
        display: flex;
        justify-content: space-around;

        ul {
          width: 50%;
          list-style: none;
  
          li {
            margin-bottom: 10px;
  
            label {
              margin-right: 10px;
              color: rgba(0, 0, 0, 0.781);
            }
          }
        }
      }

      @media only screen and (max-width: 600px) {
        ul {
          width: 100%;
          list-style: none;
  
          li {
            margin-bottom: 10px;
  
            label {
              margin-right: 10px;
              color: rgba(0, 0, 0, 0.781);
            }
          }
        }
      }
    }
  }
}
