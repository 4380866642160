.listeProduits {
  height: 100vh;

  @media only screen and (min-width: 600px) {
    .row1{
      display:flex;
      justify-content:space-between;

      div{
        margin-top: 60px;
      }

      .masque{
        font-size:13px;
        cursor:pointer;
      }
    }

    button:hover {
      transform: scale(1);
      color: $blue;
    }

    .inner {
      margin-left: 150px;
      margin-right: 100px;
      margin-bottom: 30px;

      .validate{
        display:flex;
        justify-content:center;

        div{
          width:80px;
          text-align: center;
        }

        div:first-child{
          cursor:pointer;
          color:rgb(22, 136, 22);
        }

        div:last-child{
          cursor:pointer;
          color:rgb(214, 12, 12);
        }
      }

      .ligne1 {
        display: flex;
        justify-content: space-between;

        button {
          border: 0px;
          background-color: transparent;
        }
      }

      .head {
        color: $blue;
        font-weight: bold;
      }

      .openDialog {
        cursor: pointer;
      }

      .add {
        text-align: center;

        button {
          border: none;
        }
      }

      .edit {
        color: $blue;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .inner{
      padding:20px;
    }

    .row1{
      h1{
        margin-top: 30px;
      }
  
      .masque{
        font-size:13px;
        cursor:pointer;
      }
    }

    .add {
      text-align: center;

      button {
        border: none;
      }
    }
  }
}