.dashboard {
  height: 100vh;

  .ligne1 {
    padding-top: 50px;
    padding-left: 50px;
    display: flex;
    justify-content: space-between;

    .left a {
      color: $blue;
      margin-left: 5%;
    }

    h1 {
      margin-top: -15px;
      margin-bottom: 0;
      margin-left: 60px;
    }

    .right {
      padding-right: 100px;

      .logout {
        font-size: 28px;
        cursor: pointer;
        transition: 0.5s;
      }

      .logout:hover {
        color: $red;
      }
    }
  }

  .inner {
    text-align: center;
    height: fit-content;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 22px;

    div {
      margin-bottom: 15px;
      color: rgba(25, 25, 27, 0.87);
      cursor: pointer;

      a {
        color:rgba(25, 25, 27, 0.87);
      }

      .icon {
        font-size: 22px;
        margin-right: 20px;
        color: $blue;
      }
    }
  }

  .coord {
    position: absolute;
    bottom: 50px;
    right: 20px;
    width: 100%;
    text-align: right;

    div:first-child {
      color: $red;
      font-weight: bold;
    }
  }

  .validateLink{
    color:$electric;
  }
}
