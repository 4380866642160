.pdf{
    position:  relative;
    margin:  0  auto;
    padding:  .2in  .2in;
    color:  #333;
    background-color:  #fff;
    box-sizing:  border-box;

    h2{
        font-weight: bold;
        color:$blue;
        margin-top: 0px;
    }

    h3 {
        color: $electric;
        font-weight: bold;
        margin-bottom: 0px;
        margin-top: 20px;
    }

    label{
        font-weight: bold;
    }

    .picFlex{
        img{
            float:right;
            width:40%;
            border-radius: 4px;
            margin-bottom: 20px;
            margin-left: 10px;
        }
    }

    .valNutListe {
        ul {
            margin-top: 0px;
            margin-bottom: -20px;
            list-style-type: none;

            li {
            margin-left: -40px;
            }

            li li {
            margin-left: 35px;
            list-style-type:none;
            }
        }
    }
}