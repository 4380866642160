.carte {
  .inner {
    @media only screen and (min-width: 600px) {
      margin-left: 150px;
      margin-right: 100px;
    }
    @media only screen and (max-width: 600px) {
      padding-top: 2px;
      margin-left: 50px;
      margin-right: 20px;
    }

    margin-bottom: 30px;

    .row1 {
      @media only screen and (min-width: 600px) {
        display: flex;
        justify-content: space-between;
      }

      .icon {
        font-size: 17px;
        margin-top: 30px;
        color: $electric;
        cursor: pointer;
      }
    }
  }
}


#carte{
  width:fit-content;
  display:block;
  margin:auto;
  margin-top: 10px;
  margin-bottom:10px;

  ul {
    list-style-type: none;
    text-align: center;
    margin-left:-40px;

    li {
      h2 {
        margin-bottom: -10px;
        margin-top: 20px;
        font-weight: bold;
      }

      div {
        margin-top: 0;
        margin-bottom: -10px;
      }

      label {
        font-weight: bold;
      }

      .barre {
        height: 1px;
        width: 100%;
        background-color: black;
        display: block;
        margin: auto;
        margin-top: 30px;
      }
    }
  }
}

#pdf-view-carte{
  width:580px;
  display:block;
  margin:auto;
  margin-top: 10px;
  margin-bottom:10px;

  h3{
    text-align:center;
    margin-bottom: 40px;
    font-style: italic;
  }

  ul {
    list-style-type: none;
    text-align: center;
    margin-left:-40px;

    li {
      h2 {
        margin-bottom: -10px;
        margin-top: 20px;
      }

      div {
        margin-top: 0;
        margin-bottom: -10px;
      }

      .barre {
        height: 1px;
        width: 30%;
        background-color: black;
        display: block;
        margin: auto;
        margin-top: 30px;
      }
    }
  }
}